import { httpService } from './http.service.js';

export const rmfDocumentsService = {
  fetchAll,
  fetchByType,
  fetchBySource,
  getSuggested,
  getById,
  save
};

const ENDPOINT = 'rmf-documents';

async function fetchAll() {
  return httpService.get(`${ENDPOINT}/all`);
}

async function fetchByType(type) {
  return httpService.get(`${ENDPOINT}/type/${type}`);
}

async function fetchBySource(source) {
  return httpService.get(`${ENDPOINT}/source/${source}`);
}

async function getSuggested(count) {
  return httpService.get(`${ENDPOINT}/suggested/${count}`);
}

async function getById(actionId) {
  return httpService.get(`${ENDPOINT}/${actionId}`);
}

async function save(document) {
  let savedDoc;
  if (document._id) {
    console.log('update ai product');
    savedDoc = await httpService.put(`${ENDPOINT}/${document._id}`, document);
  } else {
    console.log('create ai product');
    savedDoc = await httpService.post(`${ENDPOINT}`, document);
  }
  return savedDoc;
}
