import { httpService } from './http.service.js';
const endpoint = 'ai-product';

export const aiProductService = {
  getById,
  query,
  remove,
  save,

};

async function getById(_id) {
  return httpService.get(`${endpoint}/${_id}`);
}

async function query() {
  return httpService.get(endpoint);
}

async function remove(_id) {
  return httpService.delete(`${endpoint}/${_id}`);
}

async function save(product) {
  let savedProduct;
  if (product._id) {
    console.log('update ai product');
    savedProduct = await httpService.put(`${endpoint}/${product._id}`, product);
  } else {
    console.log('create ai product');
    savedProduct = await httpService.post(`${endpoint}`, product);
  }
  return savedProduct;
}
