import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useOrgData } from 'contexts/org-data-context';
import { DeleteIcon } from 'icons';
import { NIST_ACTION_STATUSES as actionStatuses } from 'js/constants';
import { findIndex, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { rmfActionService } from 'services/rmf-action.service';
import { rmfDocumentsService } from 'services/rmf-documents.service';
import { ai_productActorsService } from 'services/users.service';

const selectedBoxHeight = '36vh';

const RmfActionTypes = ({
  newProduct,
  onSelectedActionChange = () => null,
  onSelectedDocsChange = () => null,
  onListItemUpdate = () => null
}) => {
  const [allrmfActionsByCategory, setAllrmfActionsByCategory] = useState([]);
  const [allrmfDocsByCategory, setAllrmfDocsByCategory] = useState([]);
  const [isLoadingActions, setIsLoadingActions] = useState(false);
  const [selectedSource, setSelectedSource] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('Govern');
  const [selectedTitle, setSelectedTitle] = useState('All');
  const [searchActionQuery, setSearchActionQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const [allUsers, setAllUsers] = useState([]);
  const [editedListItem, setEditedListItem] = useState(null);
  const [isLoadingAi, setIsLoadingAi] = useState(false);

  const {
    state: { selectedCompliance }
  } = useOrgData();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (newProduct?.org_id) {
          const users = await ai_productActorsService.getUsers(newProduct?.org_id);
          console.log('product front', users);
          setAllUsers(users);
        }
      } catch (err) {
        console.error('Error loading Users', err);
      }
    };

    fetchUsers();
  }, [newProduct?.org_id]);

  useEffect(() => {
    const fetchRmfActionsAndDocs = async () => {
      setIsLoadingActions(true);
      Promise.all([
        rmfActionService.fetchAll(selectedCategory),
        rmfDocumentsService.fetchByType(selectedCategory)
      ])
        .then((results) => {
          setAllrmfActionsByCategory(results[0] || []);
          setAllrmfDocsByCategory(results[1] || []);
        })
        .catch((err) => console.error('Error loading RMF Actions', err))
        .finally(() => setIsLoadingActions(false));
    };

    fetchRmfActionsAndDocs();
  }, [selectedCategory]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchActionQuery);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchActionQuery]);

  const filteredRmfActions = useMemo(() => {
    return allrmfActionsByCategory.filter(
      (rmfAction) =>
        (selectedSource === 'all' || rmfAction.source === selectedSource) &&
        (selectedTitle === 'All' || rmfAction.title === selectedTitle) &&
        rmfAction.action.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [selectedSource, selectedTitle, debouncedSearch, allrmfActionsByCategory]);

  const filteredRmfDocs = useMemo(() => {
    return allrmfDocsByCategory.filter(
      (doc) =>
        (selectedSource === 'all' || doc.source === selectedSource) &&
        (selectedTitle === 'All' || doc.title === selectedTitle) &&
        doc.document.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [selectedSource, selectedTitle, debouncedSearch, allrmfDocsByCategory]);

  const rmfActionTitles = useMemo(() => {
    if (selectedSource === 'custom') {
      return [];
    }

    return uniqBy(
      allrmfActionsByCategory.filter(
        (o) => o.type.toLowerCase() === selectedCategory.toLowerCase()
      ),
      'title'
    );
  }, [selectedSource, selectedCategory, allrmfActionsByCategory]);

  const userMenuOpts = useMemo(() => {
    if (!editedListItem) {
      return [];
    }
    return (
      allUsers
        ?.filter((user) =>
          user.aiActors?.some((actor) => editedListItem.ai_actors?.includes(actor))
        )
        .map((user) => ({
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName
        })) ?? []
    );
  }, [editedListItem, allUsers]);

  const onSelectedSourceChange = (e) => {
    setSelectedTitle('All');
    setSelectedSource(e.target.value);
  };

  const onSelectedCategoryChange = (e) => {
    setSelectedTitle('All');
    setSelectedCategory(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update the active tab when a new tab is clicked
  };

  const selectActionsAndDocsWithAi = async (e, count = 10) => {
    console.log('selectActionsAndDocsWithAi!!', count);
    if (newProduct?.rmfActions?.length || newProduct?.selectedDocuments?.length) {
      console.warn('Ai Suggested is only for new (empty) products');
      return;
    }

    setIsLoadingAi(true);
    try {
      const results = await Promise.all([
        rmfActionService.getSuggested(count),
        rmfDocumentsService.getSuggested(count)
      ]);

      const selectedActions = [];
      for (const rmfAction of results[0]) {
        selectedActions.push({
          action_id: rmfAction._id,
          action: rmfAction.action,
          ai_actors: rmfAction.ai_actors,
          title: rmfAction.title,
          status: 'To Do',
          description: '',
          users: [],
          checked: true,
          is_ai_selected: true
        });
      }
      onSelectedActionChange(selectedActions);

      const selectedDocs = [];
      for (const doc of results[1]) {
        selectedDocs.push({
          doc_id: doc._id,
          document: doc.document,
          title: doc.title,
          ai_actors: doc.ai_actors,
          status: 'To Do',
          description: '',
          users: [],
          checked: true,
          is_ai_selected: true
        });
      }
      onSelectedDocsChange(selectedDocs);
    } catch (err) {
      console.error('Failed loading ai suggested', err);
    } finally {
      setIsLoadingAi(false);
    }
  };

  const ButtonInTabs = ({ className, onClick, children }) => {
    return (
      <Box
        className={className}
        children={children}
        sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}
      >
        <Tooltip title="Select actions and documents based on suggestions from the AI. *Requires new product*">
          <span>
            <Button
              variant="contained"
              disabled={
                isLoadingAi ||
                newProduct?.rmfActions?.length > 0 ||
                newProduct?.selectedDocuments?.length > 0
              }
              onClick={onClick}
            >
              <AutoAwesomeIcon sx={{ color: '#fff', marginRight: '0.25rem' }} /> AI SUGGESTED
            </Button>
          </span>
        </Tooltip>
        {isLoadingAi && (
          <CircularProgress
            size={24}
            sx={{
              color: '#fff',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
              width: 'auto !important',
              height: 'auto !important'
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, marginTop: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={4} lg={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ flex: 1 }}>
            <InputLabel htmlFor="rmfActionSource">Source</InputLabel>
            <Select
              id="rmfActionSource"
              name="rmfActionSource"
              value={selectedSource}
              onChange={onSelectedSourceChange}
              label="Source"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiSelect-outlined': {
                    padding: '10px'
                  }
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="nist">Nist</MenuItem>
              {selectedCompliance.indexOf('custom-policies') > -1 && (
                <MenuItem value="custom">Custom</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={4} lg={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ flex: 1 }}>
            <InputLabel htmlFor="rmfActionCategory">Category</InputLabel>
            <Select
              id="rmfActionCategory"
              name="rmfActionCategory"
              value={selectedCategory}
              onChange={onSelectedCategoryChange}
              label="Category"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiSelect-outlined': {
                    padding: '10px'
                  }
                }
              }}
            >
              <MenuItem value="Govern">Govern</MenuItem>
              <MenuItem value="Map">Map</MenuItem>
              <MenuItem value="Measure">Measure</MenuItem>
              <MenuItem value="Manage">Manage</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={4} lg={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ flex: 1 }}>
            <InputLabel htmlFor="rmfActionsTitle">Sub-Category</InputLabel>
            <Select
              id="rmfActionsTitle"
              name="rmfActionsTitle"
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
              label="Sub-Category"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiSelect-outlined': {
                    padding: '10px'
                  }
                }
              }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>
              {rmfActionTitles.map((action, index) => (
                <MenuItem key={action.title} value={action.title}>
                  {action.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={9}>
          <TextField
            fullWidth
            size="small"
            label="Search..."
            variant="outlined"
            value={searchActionQuery}
            onChange={(e) => setSearchActionQuery(e.target.value)}
            sx={{ flex: 1 }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs={3}>
          <ButtonInTabs onClick={selectActionsAndDocsWithAi} />
        </Grid>
      </Grid>
      {/* Tabs for Selected and Available Actions */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Action tabs"
        variant="fullWidth"
      >
        <Tab label={`Selected Actions (${newProduct?.rmfActions?.length ?? 0})`} />
        <Tab label={`Available Actions (${filteredRmfActions?.length ?? 0})`} />
        <Tab label={`Selected Documents (${newProduct?.selectedDocuments?.length ?? 0})`} />
        <Tab label={`Available Documents (${filteredRmfDocs?.length ?? 0})`} />
      </Tabs>
      {/* Tab Panels */}
      <Box>
        {/* SELECTED ACTIONS */}
        {activeTab === 0 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ height: selectedBoxHeight, overflow: 'auto', padding: 0 }}>
                {newProduct?.rmfActions?.length > 0 ? (
                  newProduct?.rmfActions?.map((rmfAction, index) => (
                    <ListItem
                      key={rmfAction.action_id}
                      disableGutters
                      divider={index < newProduct?.rmfActions?.length - 1}
                      sx={{ paddingY: '1rem' }}
                    >
                      <ListItemIcon
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          alignSelf: 'flex-start'
                        }}
                      >
                        <Typography variant="button">{'(' + (index + 1) + ')'}</Typography>
                        <IconButton
                          size="large"
                          aria-label="remove"
                          onClick={() =>
                            onSelectedActionChange([
                              { action_id: rmfAction.action_id, checked: false }
                            ])
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        {rmfAction.is_ai_selected && (
                          <Tooltip title="AI Selected">
                            <AutoAwesomeIcon sx={{ color: '#2ea2db' }} />
                          </Tooltip>
                        )}
                      </ListItemIcon>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body1">{rmfAction.action}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {rmfAction.title}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            paddingTop: '0.5rem'
                          }}
                        >
                          <FormControl variant="standard" size="small" sx={{ minWidth: '25%' }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                              variant="standard"
                              size="small"
                              label="Status"
                              value={rmfAction.status}
                              onChange={(e) =>
                                onListItemUpdate({
                                  index,
                                  type: 'rmfActions',
                                  key: 'status',
                                  value: e.target.value
                                })
                              }
                            >
                              {actionStatuses.map((status, index) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl fullWidth variant="standard" size="small">
                            <InputLabel>Users</InputLabel>
                            <Select
                              displayEmpty
                              variant="standard"
                              size="small"
                              label="Users"
                              name="users"
                              multiple
                              value={rmfAction.users?.map((user) => user._id) ?? []} // Use user IDs as the value
                              onOpen={() => setEditedListItem(rmfAction)}
                              onClose={() => setEditedListItem(null)}
                              onChange={(e) => {
                                const { value } = e.target;

                                const selectedUsers = value
                                  .map((userId) => {
                                    const user = allUsers.find((u) => u._id === userId);
                                    return user
                                      ? {
                                          _id: user._id,
                                          firstName: user.firstName,
                                          lastName: user.lastName
                                        }
                                      : null;
                                  })
                                  .filter(Boolean);

                                onListItemUpdate({
                                  index,
                                  type: 'rmfActions',
                                  key: 'users',
                                  value: selectedUsers
                                });
                              }}
                              renderValue={(selected) =>
                                selected
                                  .map((userId) => {
                                    const user = allUsers.find((u) => u._id === userId);
                                    return user ? user.firstName + ' ' + user.lastName : '';
                                  })
                                  .join(', ')
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: { maxHeight: 400, position: 'relative', maxWidth: 200 }
                                }
                              }}
                            >
                              {userMenuOpts?.map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  <Checkbox
                                    key={rmfAction.users?.some((u) => u._id === user._id)}
                                    checked={rmfAction.users?.some((u) => u._id === user._id)}
                                  />{' '}
                                  <ListItemText primary={user.firstName + ' ' + user.lastName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <TextField
                          variant="standard"
                          size="small"
                          label="Description"
                          fullWidth
                          value={rmfAction.description ?? ''}
                          onChange={(e) =>
                            onListItemUpdate({
                              index,
                              type: 'rmfActions',
                              key: 'description',
                              value: e.target.value
                            })
                          }
                          sx={{ marginTop: '0.25rem' }}
                        />
                      </Box>
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No selected actions" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}

        {/* AVAILABLE ACTIONS */}
        {activeTab === 1 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ height: selectedBoxHeight, overflow: 'auto', padding: 0 }}>
                {filteredRmfActions.length > 0 ? (
                  filteredRmfActions.map((rmfAction, index) => (
                    <ListItem
                      key={rmfAction._id}
                      disableGutters
                      divider={index < filteredRmfActions.length - 1}
                      sx={{ paddingTop: 0.5, paddingBottom: 0.5, marginTop: 0, marginBottom: 0 }}
                    >
                      <ListItemIcon sx={{ justifyContent: 'center' }}>
                        <Checkbox
                          edge="start"
                          checked={
                            findIndex(newProduct.rmfActions, { action_id: rmfAction._id }) > -1
                          }
                          tabIndex={-1}
                          disableRipple
                          onChange={(e) =>
                            onSelectedActionChange([
                              {
                                action_id: rmfAction._id,
                                action: rmfAction.action,
                                ai_actors: rmfAction.ai_actors,
                                title: rmfAction.title,
                                status: 'To Do',
                                description: '',
                                users: [],
                                checked: e.target.checked
                              }
                            ])
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={rmfAction.action} secondary={rmfAction.title} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No actions available" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}
        {/* SELECTED DOCUMENTS */}
        {activeTab === 2 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ height: selectedBoxHeight, overflow: 'auto', padding: 0 }}>
                {newProduct?.selectedDocuments?.length > 0 ? (
                  newProduct?.selectedDocuments?.map((doc, index) => (
                    <ListItem
                      key={doc.doc_id}
                      disableGutters
                      divider={index < newProduct?.selectedDocuments?.length - 1}
                      sx={{ paddingY: '1rem' }}
                    >
                      <ListItemIcon
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          alignSelf: 'flex-start'
                        }}
                      >
                        <Typography variant="button">{'(' + (index + 1) + ')'}</Typography>
                        <IconButton
                          size="large"
                          aria-label="remove"
                          onClick={() =>
                            onSelectedDocsChange([{ doc_id: doc.doc_id, checked: false }])
                          }
                        >
                          <DeleteIcon sx={{ color: '#2ea2db' }} />
                        </IconButton>
                        {doc.is_ai_selected && (
                          <Tooltip title="AI Selected">
                            <AutoAwesomeIcon sx={{ color: '#2ea2db' }} />
                          </Tooltip>
                        )}
                      </ListItemIcon>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body1">{doc.document}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {doc.title}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            paddingTop: '0.5rem'
                          }}
                        >
                          <FormControl variant="standard" size="small" sx={{ minWidth: '25%' }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                              variant="standard"
                              label="Status"
                              value={doc.status}
                              onChange={(e) =>
                                onListItemUpdate({
                                  index,
                                  type: 'selectedDocuments',
                                  key: 'status',
                                  value: e.target.value
                                })
                              }
                            >
                              {actionStatuses.map((status, index) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl fullWidth variant="standard" size="small">
                            <InputLabel>Users</InputLabel>
                            <Select
                              displayEmpty
                              variant="standard"
                              size="small"
                              label="Users"
                              name="users"
                              multiple
                              value={doc.users?.map((user) => user._id) ?? []}
                              onOpen={() => setEditedListItem(doc)}
                              onClose={() => setEditedListItem(null)}
                              onChange={(e) => {
                                const { value } = e.target;

                                const selectedUsers = value
                                  .map((userId) => {
                                    const user = allUsers.find((u) => u._id === userId);
                                    return user
                                      ? {
                                          _id: user._id,
                                          firstName: user.firstName,
                                          lastName: user.lastName
                                        }
                                      : null;
                                  })
                                  .filter(Boolean);

                                onListItemUpdate({
                                  index,
                                  type: 'selectedDocuments',
                                  key: 'users',
                                  value: selectedUsers
                                });
                              }}
                              renderValue={(selected) =>
                                selected
                                  .map((userId) => {
                                    const user = allUsers.find((u) => u._id === userId);
                                    return user ? user.firstName + ' ' + user.lastName : '';
                                  })
                                  .join(', ')
                              }
                              MenuProps={{
                                PaperProps: {
                                  sx: { maxHeight: 400, position: 'relative', maxWidth: 200 }
                                }
                              }}
                            >
                              {userMenuOpts?.map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  <Checkbox
                                    key={doc.users?.some((u) => u._id === user._id)}
                                    checked={doc.users?.some((u) => u._id === user._id)}
                                  />{' '}
                                  <ListItemText primary={user.firstName + ' ' + user.lastName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <TextField
                          variant="standard"
                          size="small"
                          label="Description"
                          fullWidth
                          value={doc.description ?? ''}
                          onChange={(e) =>
                            onListItemUpdate({
                              index,
                              type: 'selectedDocuments',
                              key: 'description',
                              value: e.target.value
                            })
                          }
                          sx={{ marginTop: '0.25rem' }}
                        />
                      </Box>
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No selected documents" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}
        {/* AVAILABLE DOCUMENTS */}
        {activeTab === 3 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ height: selectedBoxHeight, overflow: 'auto', padding: 0 }}>
                {filteredRmfDocs.length > 0 ? (
                  filteredRmfDocs.map((doc, index) => (
                    <ListItem
                      key={doc._id}
                      disableGutters
                      divider={index < filteredRmfDocs.length - 1}
                      sx={{ paddingTop: 0.5, paddingBottom: 0.5, marginTop: 0, marginBottom: 0 }}
                    >
                      <ListItemIcon sx={{ justifyContent: 'center' }}>
                        <Checkbox
                          edge="start"
                          checked={
                            findIndex(newProduct.selectedDocuments, { doc_id: doc._id }) > -1
                          }
                          tabIndex={-1}
                          disableRipple
                          onChange={(e) =>
                            onSelectedDocsChange([
                              {
                                doc_id: doc._id,
                                title: doc.title,
                                ai_actors: doc.ai_actors,
                                document: doc.document,
                                description: '',
                                status: 'To Do',
                                users: [],
                                checked: e.target.checked
                              }
                            ])
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={doc.document} secondary={doc.title} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No documents available" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RmfActionTypes;
