import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PendingIcon from '@mui/icons-material/Pending';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useOrgData } from 'contexts/org-data-context';
import {
  NIST_ACTION_STATUSES as ACTION_STATUSES,
  NIST_ACTION_TYPES as ACTION_TYPES
} from 'js/constants';
import cloneDeep from 'lodash/cloneDeep';
import sum from 'lodash/sum';
import React, { useEffect, useMemo, useState } from 'react';
import { aiProductService } from 'services/ai-product.service';
import ComplianceStatusTable from '../rmf/popovers/compliance-status-table';

const tableElevation = 2;

const DashboardStatusTotal = () => {
  const [aiProducts, setAiProducts] = useState([]);

  const {
    state: { selectedOrgList }
  } = useOrgData();

  useEffect(() => {
    const loadAiProducts = async () => {
      const allAiProducts = await aiProductService.query();

      // Filter AI products to only include those whose org_id is in selectedOrgList
      const filteredAiProducts = allAiProducts.filter((product) =>
        selectedOrgList.some((org) => org._id === product.org_id)
      );

      setAiProducts(filteredAiProducts);
    };

    loadAiProducts();
  }, [selectedOrgList]);

  // Generate the status data from aiProducts
  const statusData = useMemo(() => {
    // eslint-disable-next-line no-sequences
    const statusObj = ACTION_STATUSES.reduce((acc, curr) => ((acc[curr] = [0, 0]), acc), {});
    const result = ACTION_TYPES.reduce(
      // eslint-disable-next-line no-sequences
      (acc, curr) => ((acc[curr] = cloneDeep(statusObj)), acc),
      {}
    );

    aiProducts.forEach((product) => {
      if (Array.isArray(product?.rmfActions)) {
        product.rmfActions.forEach((action) => {
          const typeKey = action.title ? action.title.split(' ')[0].toLowerCase() : null;
          const status = action.status ?? 'To Do';
          if (Number.isFinite(result?.[typeKey]?.[status]?.[0])) {
            result[typeKey][status][0]++;
          }
        });
      }
      if (Array.isArray(product?.selectedDocuments)) {
        product.selectedDocuments.forEach((doc) => {
          const typeKey = doc.title ? doc.title.split(' ')[0].toLowerCase() : null;
          const status = doc.status ?? 'To Do';
          if (Number.isFinite(result?.[typeKey]?.[status]?.[1])) {
            result[typeKey][status][1]++;
          }
        });
      }
    });

    return result;
  }, [aiProducts]);

  return (
    <React.Fragment>
      <Paper elevation={0} square={false} style={{ width: '100%' }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ padding: 3 }}>
          Status Summary
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Grid container spacing={3}>
            <Grid xs={12}>
              {/* Total Table */}
              <Paper elevation={tableElevation} square={false}>
                <Table sx={{ minWidth: '25rem' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingY: '0.75rem' }}>Total</TableCell>
                      <TableCell sx={{ paddingY: '0.75rem' }}>Actions</TableCell>
                      <TableCell sx={{ paddingY: '0.75rem' }}>Documents</TableCell>
                      <TableCell sx={{ paddingY: '0.75rem' }}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ACTION_STATUSES.map((status) => (
                      <TableRow key={status}>
                        <TableCell
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          {(() => {
                            switch (status) {
                              case 'To Do':
                                return <NotStartedIcon color="secondary" />;
                              case 'In Progress':
                                return <PendingIcon color="primary" />;
                              case 'Completed':
                                return <CheckCircleIcon color="success" />;
                              default:
                                return null;
                            }
                          })()}
                          <Typography
                            variant="button"
                            sx={{ color: 'grey.600', paddingLeft: '0.5rem' }}
                          >
                            {status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="button" sx={{ color: 'secondary.main' }}>
                            {sum(Object.values(statusData).map((type) => type[status][0])) ?? 0}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="button" sx={{ color: 'secondary.main' }}>
                            {sum(Object.values(statusData).map((type) => type[status][1])) ?? 0}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="button" sx={{ color: 'secondary.main' }}>
                            {sum(Object.values(statusData).map((type) => sum(type[status]))) ?? 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid xs={12} lg={6}>
              {/* Govern Table */}
              <Paper elevation={tableElevation} square={false}>
                <ComplianceStatusTable label="Govern" data={statusData['govern']} />
              </Paper>
            </Grid>
            <Grid xs={12} lg={6}>
              <Paper elevation={tableElevation} square={false}>
                <ComplianceStatusTable label="Map" data={statusData['map']} />
              </Paper>
            </Grid>
            <Grid xs={12} lg={6}>
              <Paper elevation={tableElevation} square={false}>
                <ComplianceStatusTable label="Measure" data={statusData['measure']} />
              </Paper>
            </Grid>
            <Grid xs={12} lg={6}>
              <Paper elevation={tableElevation} square={false}>
                <ComplianceStatusTable label="Manage" data={statusData['manage']} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default DashboardStatusTotal;
