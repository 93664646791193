const NIST_ACTION_TYPES = ['govern', 'map', 'measure', 'manage'];
const NIST_ACTION_STATUSES = ['To Do', 'In Progress', 'Completed'];

const COMPLIANCE_TABS = [
  { name: 'DASHBOARD', route: 'dashboard' },
  { name: 'USERS', route: 'users' },
  { name: 'PRODUCTS', route: 'products' },
  { name: 'ACTIONS', route: 'actions' },
  { name: 'DOCUMENTS', route: 'documents' },
  { name: 'NIST', route: 'nist' }
];

const COMPLIANCE_DYNAMIC_TABS = [
  { name: 'Canada', route: 'canada' },
  { name: 'EU', route: 'eu' },
  { name: 'UK', route: 'uk' },
  { name: 'CUSTOM POLICIES', route: 'custom-policies' }
];

// Directly reference the actual icon components instead of their string names.
const VERTICAL_TABS = [
  { name: 'STATUS', route: 'status', icon: 'dashboard' },
  { name: 'PRODUCTS', route: 'products', icon: 'products' },
  { name: 'ACTIONS', route: 'actions', icon: 'actions' }
];

const NIST_COLOR_MAP = {
  map: '#42a5f5',
  govern: '#1976D2',
  measure: '#0288d1',
  manage: '#01579b'
};

const PRODUCT_TYPES = ['Third party', 'Internal'];

export {
  COMPLIANCE_DYNAMIC_TABS,
  COMPLIANCE_TABS,
  NIST_ACTION_STATUSES,
  NIST_ACTION_TYPES,
  NIST_COLOR_MAP,
  PRODUCT_TYPES,
  VERTICAL_TABS
};
