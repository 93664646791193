import { httpService } from './http.service.js';

// const STORAGE_KEY = 'org/tree';

export const orgService = {
  queryAllRoots,
  getOrgList,
  save,
  remove,
  getEmptyOrg,
  getOrgTree,
  queryTree,
  queryAlltraceBox
};

async function queryAlltraceBox() {
  return httpService.get('tracebox-products');
}
async function queryAllRoots() {
  return httpService.get('org/all');
}

async function getOrgList(_id) {
  return httpService.get(`org/${_id}`);
}
async function queryTree(_id) {
  return httpService.get(`org/tree/${_id}`);
}

async function remove(_id) {
  return httpService.delete(`subsidary/${_id}`);
}

async function save(org) {
  console.log('service org front', org);
  var savedOrg;
  if (org._id) {
    console.log('update fronted service', org);
    savedOrg = await httpService.put(`subsidary/${org._id}`, org);
  } else {
    // org.org_id = '66a3e92218ec387a5b73a56f';
    savedOrg = await httpService.post('subsidary', org);
  }
  return savedOrg;
}

function getEmptyOrg() {
  return {
    name: ''
  };
}

async function getOrgTree(rootId) {
  const orgs = await getOrgList(rootId);
  if (!orgs) {
    return null;
  }

  return orgs.find((org) => org._id === org.org_id);

  //   // Create a map of orgs by their ID for easy lookup
  //   const orgMap = {};
  //   orgs.forEach((org) => {
  //     orgMap[org._id] = { ...org, children: [] };
  //   });

  //   // Build the tree structure
  //   Object.values(orgMap).forEach((org) => {
  //     if (org.parent_id && orgMap[org.parent_id]) {
  //       orgMap[org.parent_id].children.push(org);
  //     }
  //   });

  //   const root = orgMap[rootId] || null;
  //   // console.log("root tree", root);
  //   console.log('root tree', root);
  //   return root;
}

// const items = [
//
//     {
//       id: 2,
//       name: "Intel",
//       url: "https://logos-world.net/wp-content/uploads/2021/09/Intel-Logo-2006-2020-700x394.png"
//     },
//     {
//       id: 3,
//       name: "Apple",
//       url: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
//     },
//     {
//       id: 4,
//       name: "Microsoft",
//       url: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
//     },
//     {
//       id: 5,
//       name: "Google",
//       url: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
//     },
//     {
//       id: 6,
//       name: "Amazon",
//       url: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
//     },
//     {
//       id: 7,
//       name: "Facebook",
//       url: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
//     },
//     {
//       id: 8,
//       name: "Samsung",
//       url: "https://upload.wikimedia.org/wikipedia/commons/2/24/Samsung_Logo.svg"
//     },
//     {
//       id: 9,
//       name: "Tesla",
//       url: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"
//     },
//     {
//       id: 10,
//       name: "IBM",
//       url: "https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg"
//     }
//   ];
