import { useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AIProductCreateEdit from './ai-product-create-edit';

const ComplianceAIProduct = () => {
  const {
    state: { selectedOrgId }
  } = useOrgData();
  const [initialOrgId, setInitialOrgId] = useState(null);
  const { productId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrgId && !initialOrgId) {
      setInitialOrgId(selectedOrgId);
    } else if (initialOrgId && initialOrgId !== selectedOrgId) {
      navigate('../../products', { relative: 'path', replace: true });
    }
  }, [selectedOrgId, initialOrgId, navigate]);

  /* 

  const handleCloseProductDialog = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

    const saveProduct = async (product) => {
    await aiProductService.save(product);
    loadAiProducts();
    handleCloseProductDialog();
  };

*/

  return (
    <React.Fragment>
      <AIProductCreateEdit productId={productId} aiProductOrgId={selectedOrgId} />
    </React.Fragment>
  );
};

export default ComplianceAIProduct;
