import { Box } from '@mui/material';
import Header from 'components/header/header';
import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { httpService } from 'services/http.service';
import './App.css';

import { GoogleOAuthProvider } from '@react-oauth/google';

import ComplianceAIProduct from 'components/ai-product/compliance-ai-product';
import ComplianceAIProducts from 'components/ai-product/compliance-ai-products';
import ComplianceActions from 'components/compliance/actions/compliance-actions';
import ComplianceCustomPolicies from 'components/compliance/custom-policies/compliance-custom-policies';
import ComplianceCustomPolicy from 'components/compliance/custom-policies/compliance-custom-policy';
import Dashboard from 'components/compliance/dashboard/dashboard';
import CompalianceDocuments from 'components/compliance/documents/compliance-documents';
import ComplianceCanada from 'components/compliance/dynamic-policies/compliance-canada';
import ComplianceEuAct from 'components/compliance/dynamic-policies/compliance-eu';
import ComplianceUK from 'components/compliance/dynamic-policies/compliance-uk';
import ComplianceRMF from 'components/compliance/rmf/compliance-rmf';
import ComplianceUsers from 'components/compliance/users/compliance-users';
import ComplianceIndex from 'pages/compliance-index';
import Home from 'pages/home';
import NotFoundIndex from 'pages/not-found-index';
import OrgIndex from 'pages/org-index';
import SignIn from 'pages/sign-in'; // Import additional pages as needed
import DashboardProductsType from 'components/compliance/dashboard/dashboard-products-type';
import DashboardStatusTotal from 'components/compliance/dashboard/dashboard-status-total';
import DashboardActionTotal from 'components/compliance/dashboard/dashboard-action-total';

const UserContext = createContext(null);
export const useUserContext = () => {
  return useContext(UserContext);
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSignedIn, setIsSignedIn] = useState(!!window.sessionStorage.getItem('user'));
  const [user, setUser] = useState(null);

  const signOut = async () => {
    console.log('signOut!!!');
    window.sessionStorage.clear();

    if (isSignedIn) {
      setIsSignedIn(false);
      let result = await httpService.get('auth/signout');
      console.log(result);
    }
    if (location.pathname !== '/signin') {
      navigate('/signin');
    }
  };

  useEffect(() => {
    let currUser = null;
    try {
      currUser = JSON.parse(window.sessionStorage.getItem('user'));
    } catch (err) {}
    if (location.pathname === '/signin' || !currUser || !currUser.email) {
      signOut();
    } else {
      setIsSignedIn(true);
      setUser(currUser);
    }
    // TODO use custom hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Box
        className="App"
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100lvh' }}
      >
        <UserContext.Provider
          value={{
            user,
            setUser
          }}
        >
          <Header isSignedIn={isSignedIn} onSignOut={signOut} />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/compliance/:orgId" element={<ComplianceIndex />}>
              <Route path="dashboard" element={<Dashboard />}>
                {/* Nested products route under dashboard */}
                <Route path="status" element={<DashboardStatusTotal />} />
                <Route path="products" element={<DashboardProductsType />} />
                <Route path="actions" element={<DashboardActionTotal />} />
                <Route index element={<Navigate to="status" replace />} />
              </Route>
              <Route path="users" element={<ComplianceUsers />} />
              <Route path="nist" element={<ComplianceRMF />} />
              <Route path="products" element={<ComplianceAIProducts />} />
              <Route path="product/:productId?" element={<ComplianceAIProduct />} />
              <Route path="actions" element={<ComplianceActions />} />
              <Route path="documents" element={<CompalianceDocuments />} />
              <Route index element={<Navigate to="users" replace />} />
              {/* Dynamic Compliance Routes */}
              <Route path="canada" element={<ComplianceCanada />} />
              <Route path="eu" element={<ComplianceEuAct />} />
              <Route path="uk" element={<ComplianceUK />} />
              <Route path="custom-policies" element={<ComplianceCustomPolicies />} />
              <Route
                path="custom-policy/:policyType/:policyId?"
                element={<ComplianceCustomPolicy />}
              />
              {/* Dynamic Compliance Routes END */}
              <Route index element={<Navigate to="dashboard" replace />} />
            </Route>
            <Route path="/org" element={<OrgIndex />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<NotFoundIndex />} />
            {/* Add more routes as needed */}
          </Routes>
        </UserContext.Provider>
      </Box>
    </GoogleOAuthProvider>
  );
}

export default App;
