import { Box, Container } from '@mui/material';
import ComplianceTabs from 'components/compliance/compliance-tabs';
import { OrgDataProvider } from 'contexts/org-data-context';
import { Outlet } from 'react-router-dom';
import TreeHirarchicalList from '../components/compliance/tree-hirarchical-list';

const ComplianceIndex = () => {
  return (
    <OrgDataProvider>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Box sx={{ width: '250px', minWidth: '18vw', borderRight: '1px solid #ccc', padding: 2 }}>
          <TreeHirarchicalList />
        </Box>

        <Container sx={{ paddingTop: '1rem', marginLeft: 1 }}>
          <ComplianceTabs />
          <Outlet />
        </Container>
      </Box>
    </OrgDataProvider>
  );
};

export default ComplianceIndex;
