// AiProductList.jsx
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import SearchInput from 'components/ai-product/ai_product_search';
import PaginationFormControl from 'components/pagination';
import { DeleteIcon, EditIcon } from 'icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rmfActionService } from 'services/rmf-action.service';
import { rmfDocumentsService } from 'services/rmf-documents.service';

const CustomPoliciesList = ({ selectedOrgId, selectedOrgList }) => {
  const [customPolicies, setCustomPolicies] = useState([]); // Initialize as empty array
  const [customPolicyQuery, setCustomPolicyQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const navigate = useNavigate();
  useEffect(() => {
    const loadCustomPolicies = async () => {
      try {
        const results = await Promise.all([
          rmfActionService.fetchBySource('custom'),
          rmfDocumentsService.fetchBySource('custom')
        ]);

        const allPolicies = results[0].concat(results[1]);
        // Ensure allPolicies is an array before filtering
        const filteredPolicies = allPolicies.filter((action) => action.org_id === selectedOrgId);
        setCustomPolicies(filteredPolicies);
      } catch (err) {
        console.error('Failed to load custom policies:', err);
      }
    };

    loadCustomPolicies();
    setCurrentPage(1);
  }, [selectedOrgId]);

  const getOrgNameById = (orgId) => {
    const org = selectedOrgList.find((org) => org._id === orgId);
    return org ? org.name : 'Unknown';
  };

  const deleteCustomPolicy = async (productId) => {
    // TODO add confirmation before deleting
    // await aiProductService.remove(productId);
    // loadAiProducts();
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filteredCustomPolicies = (customPolicies || []).filter((customPolicy) => {
    const orgName = getOrgNameById(customPolicy.org_id);
    return (
      customPolicy.action?.toLowerCase().includes(customPolicyQuery.toLowerCase()) ||
      customPolicy.type?.toLowerCase().includes(customPolicyQuery.toLowerCase()) ||
      orgName.toLowerCase().includes(customPolicyQuery.toLowerCase())
    );
  });

  // Calculate pagination
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredCustomPolicies.slice(indexOfFirstProduct, indexOfLastProduct);
  const pageCount = Math.ceil(filteredCustomPolicies.length / itemsPerPage);

  const navigateToCreateEditCustomPolicy = (customPolicy) => {
    if (customPolicy) {
      const type = customPolicy.action ? 'action' : customPolicy.document ? 'document' : null;
      if (type === null) throw new Error('Invalid policy type.');
      return navigate(`../custom-policy/${type}/${customPolicy._id}`, { relative: 'path' });
    } else {
      return navigate(`../custom-policy/new`, { relative: 'path' });
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ gap: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigateToCreateEditCustomPolicy()}
          sx={{ width: '10rem' }}
        >
          New Policy
        </Button>
        <SearchInput
          searchQuery={customPolicyQuery}
          setSearchQuery={setCustomPolicyQuery}
          label="Search Custom Policies..."
        />

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Org Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>AI Actors</TableCell>
              <TableCell>Policy</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProducts?.length < 1 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No custom policies found
                </TableCell>
              </TableRow>
            ) : (
              customPolicies?.map((customPolicy) => (
                <TableRow key={customPolicy._id}>
                  <TableCell>{getOrgNameById(customPolicy.org_id)}</TableCell>
                  <TableCell>{customPolicy.action ? 'Action' : 'Document'}</TableCell>
                  <TableCell>{customPolicy.type}</TableCell>
                  <TableCell>
                    {customPolicy.ai_actors && customPolicy.ai_actors.length > 0
                      ? customPolicy.ai_actors.map((actor, index) => <div key={index}>{actor}</div>)
                      : 'No actors available'}
                  </TableCell>
                  <TableCell>
                    {customPolicy.action ? customPolicy.action : customPolicy.document}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => navigateToCreateEditCustomPolicy(customPolicy)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteCustomPolicy(customPolicy)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomPoliciesList;
