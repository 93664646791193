import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { cloneDeep, findIndex } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { aiProductService } from 'services/ai-product.service';
import { traceboxService } from 'services/tracebox-products.service';
import RmfActionTypes from './rmf-action/rmf-action-types';

const defaultProduct = () => {
  return {
    _id: null,
    product_name: '',
    description: '',
    org_id: null,
    type: 'Internal',
    department: '',
    rmfActions: [],
    selectedDocuments: []
  };
};

const AIProductCreateEdit = ({ productId, aiProductOrgId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [traceboxProducts, setTraceboxProducts] = useState([]);
  const [prevProductId, setPrevProductId] = useState('');
  const [newProduct, setNewProduct] = useState({
    ...defaultProduct(),
    org_id: aiProductOrgId
  });

  const navigate = useNavigate();

  useEffect(() => {
    getTraceboxProducts();
  }, []);

  const getTraceboxProducts = async () => {
    const traceboxProducts = await traceboxService.queryAlltraceboxProducts();
    setTraceboxProducts(traceboxProducts);
  };

  const fetchProduct = async () => {
    console.log('FETCH PRODUCT!', productId);
    try {
      setIsLoading(true);
      const updatedProduct = await aiProductService.getById(productId);
      setNewProduct(updatedProduct);
    } finally {
      setIsLoading(false);
    }
  };

  if (productId !== prevProductId) {
    setPrevProductId(productId);
    if (!productId) {
      const tempProduct = {
        ...defaultProduct(),
        org_id: aiProductOrgId
      };
      setNewProduct(tempProduct);
    } else {
      fetchProduct();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value
    }));
  };

  const handleRmfActionsChange = (selectedActions) => {
    console.log('handleRmfActionsChange', selectedActions);
    // { action_id, action, title, ai_actors, status, checked }

    const rmfActions = cloneDeep(newProduct.rmfActions ?? []);
    for (const rmfAction of selectedActions) {
      const index = findIndex(rmfActions, { action_id: rmfAction.action_id });
      if (rmfAction.checked && index < 0) {
        delete rmfAction.checked;
        rmfAction.timestamp = new Date().getTime();
        rmfActions.push(rmfAction);
      } else if (!rmfAction.checked && index > -1) {
        rmfActions.splice(index, 1);
      }
    }

    console.log('new rmfActions!', rmfActions);
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      rmfActions
    }));
  };

  const handleRmfDocsChange = (docs) => {
    console.log('handleRmfDocsChange', docs, docs?.[0]?.checked);
    //     { doc_id, document, title, ai_actors, status, checked, is_ai_selected }

    const selectedDocuments = cloneDeep(newProduct.selectedDocuments ?? []);

    for (const doc of docs) {
      const index = findIndex(selectedDocuments, { doc_id: doc.doc_id });
      if (doc.checked && index < 0) {
        delete doc.checked;
        doc.timestamp = new Date().getTime();

        selectedDocuments.push(doc);
      } else if (!doc.checked && index > -1) {
        selectedDocuments.splice(index, 1);
      }
    }

    setNewProduct((prevProduct) => ({
      ...prevProduct,
      selectedDocuments
    }));
  };

  const onRmfListItemUpdate = ({ index, type, key, value }) => {
    console.log('onRmfListItemUpdate???', type, index, key, value);
    const updatedData = cloneDeep(newProduct[type]);
    updatedData[index][key] = value;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [type]: updatedData
    }));
  };

  const handleSubmit = async () => {
    console.log('handleSubmit', newProduct);
    try {
      const result = await aiProductService.save(newProduct);
      if (!productId) {
        return navigate(`./${result._id}`, { relative: 'path' });
      } else {
        await fetchProduct();
      }
    } finally {
    }
  };

  const handleRevert = async () => {
    await fetchProduct();
  };

  return (
    <React.Fragment>
      <CardContent sx={{ padding: 0 }}>
        <Typography variant="subtitle2" component="div">
          {productId ? `Edit Product - ${newProduct?.product_name}` : `New Product`}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small" margin="dense" variant="outlined">
              <InputLabel>Type</InputLabel>
              <Select name="type" value={newProduct.type} onChange={handleChange} label="Type">
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="Third party">Third party</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={5}>
            {
              <TextField
                select={newProduct.type === 'Internal'}
                size="small"
                autoFocus
                margin="dense"
                name="product_name"
                label="Product Name"
                type="text"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={newProduct.product_name}
                onChange={handleChange}
              >
                {newProduct.type === 'Internal' &&
                  traceboxProducts.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.id}
                    </MenuItem>
                  ))}
              </TextField>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              margin="dense"
              size="small"
              name="department"
              label="Department"
              variant="outlined"
              value={newProduct.department}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="sales">Sales</MenuItem>
              <MenuItem value="marketing">Marketing</MenuItem>
              <MenuItem value="hr">HR</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <TextField
          size="small"
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={2}
          value={newProduct.description}
          onChange={handleChange}
          sx={{ marginTop: '1rem' }}
          InputLabelProps={{ shrink: true }}
        />

        <RmfActionTypes
          newProduct={newProduct}
          onSelectedActionChange={handleRmfActionsChange}
          onSelectedDocsChange={handleRmfDocsChange}
          onListItemUpdate={onRmfListItemUpdate}
        />
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-around', width: '30rem', maxWidth: '100%' }}
        >
          <Button variant="contained" color="error" onClick={handleRevert}>
            Revert Changes
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );
};

export default AIProductCreateEdit;
