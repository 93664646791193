// src/components/RmfMap.js

import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, IconButton, Popover } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useId, useMemo, useRef, useState } from 'react';
import nistRmfGraphImg from '../../../assets/img/nist-rmf-graph.png';
import ComplianceStatusTable from './popovers/compliance-status-table';
import sum from 'lodash/sum';
import {
  NIST_ACTION_TYPES as ACTION_TYPES,
  NIST_ACTION_STATUSES as ACTION_STATUSES
} from 'js/constants';

const defaultStyle = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  color: '#225A8A',
  fontWeight: 700,
  fontSize: '1.35rem',
  fontFamily: 'Roboto',
  padding: '.25rem',
  borderRadius: '.25rem',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center'
};

const RmfMap = ({ aiProducts = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popProps, setPopProps] = useState({});
  const [popData, setPopData] = useState({});
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const popId = useId();
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event, customProps, type) => {
    setPopProps(customProps);
    setPopData(statusData[type]);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const statusData = useMemo(() => {
    const statusObj = ACTION_STATUSES.reduce((acc, curr) => ((acc[curr] = [0, 0]), acc), {});
    const result = ACTION_TYPES.reduce(
      (acc, curr) => ((acc[curr] = cloneDeep(statusObj)), acc),
      {}
    );

    aiProducts.forEach((product) => {
      if (Array.isArray(product?.rmfActions)) {
        product.rmfActions.forEach((action) => {
          let typeKey = action.title ? action.title.split(' ')[0].toLowerCase() : null;
          const status = action.status ?? 'To Do';
          if (Number.isFinite(result?.[typeKey]?.[status]?.[0])) {
            result[typeKey][status][0]++;
          } else {
            console.warn(`Unknown type: ${typeKey}, status: ${status} combination for rmfAction.`);
          }
        });
      }
      if (Array.isArray(product?.selectedDocuments)) {
        product.selectedDocuments.forEach((doc) => {
          let typeKey = doc.title ? doc.title.split(' ')[0].toLowerCase() : null;
          const status = doc.status ?? 'To Do';
          if (Number.isFinite(result?.[typeKey]?.[status]?.[1])) {
            result[typeKey][status][1]++;
          } else {
            console.warn(
              `Unknown type: ${typeKey}, status: ${status} combination for selectedDocument.`
            );
          }
        });
      }
    });
    return result;
  }, [aiProducts]);

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
          width: '100%',
          maxWidth: '43rem',
          mx: 'auto'
        }}
      >
        <Box
          component="img"
          src={nistRmfGraphImg}
          alt="NIST RMF graph"
          sx={{
            width: '100%',
            height: 'auto'
          }}
          onLoad={(e) => setIsImageLoaded(true)}
        />
        {isImageLoaded && (
          <section>
            <Box
              sx={{
                ...defaultStyle,
                top: '89%',
                left: '52%'
              }}
            >
              {(Object.values(statusData?.manage) ?? [0]).reduce((acc, val) => acc + sum(val), 0)}
              <IconButton
                aria-label="Statuses"
                aria-owns={open ? popId : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => {
                  handlePopoverOpen(
                    e,
                    {
                      anchorOrigin: {
                        vertical: 'center',
                        horizontal: 'right'
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      }
                    },
                    'manage'
                  );
                }}
                onMouseLeave={handlePopoverClose}
              >
                <AssignmentIcon />
              </IconButton>
            </Box>
            {/* measure */}
            <Box
              sx={{
                ...defaultStyle,
                top: '26%',
                left: '83%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }}
            >
              {(Object.values(statusData?.measure) ?? [0]).reduce((acc, val) => acc + sum(val), 0)}
              <IconButton
                aria-label="Statuses"
                aria-owns={open ? popId : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => {
                  handlePopoverOpen(
                    e,
                    {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      }
                    },
                    'measure'
                  );
                }}
                onMouseLeave={handlePopoverClose}
              >
                <AssignmentIcon />
              </IconButton>
            </Box>
            {/* map */}
            <Box
              sx={{
                ...defaultStyle,
                top: '26%',
                left: '18%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }}
            >
              {(Object.values(statusData?.map) ?? [0]).reduce((acc, val) => acc + sum(val), 0)}
              <IconButton
                aria-label="Statuses"
                aria-owns={open ? popId : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => {
                  handlePopoverOpen(
                    e,
                    {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      }
                    },
                    'map'
                  );
                }}
                onMouseLeave={handlePopoverClose}
              >
                <AssignmentIcon />
              </IconButton>
            </Box>
            {/* govern */}
            <Box
              sx={{
                ...defaultStyle,
                top: '48%',
                left: '50%',
                color: 'white'
              }}
            >
              {(Object.values(statusData?.govern) ?? [0]).reduce((acc, val) => acc + sum(val), 0)}
              <IconButton
                aria-label="Statuses"
                aria-owns={open ? popId : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => {
                  handlePopoverOpen(
                    e,
                    {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      }
                    },
                    'govern'
                  );
                }}
                onMouseLeave={handlePopoverClose}
              >
                <AssignmentIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </section>
        )}
      </Box>
      <Popover
        {...popProps}
        id={popId}
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ComplianceStatusTable data={popData} />
      </Popover>
    </React.Fragment>
  );
};

export default RmfMap;
