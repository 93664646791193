import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import OrgComplianceForm from './org-compliance-form';
import OrgSelectParent from './org-select-parent';

const defaultOrg = () => {
  return {
    name: '',
    rmf: {
      manage: 0,
      measure: 0,
      map: 0,
      govern: 0
    },
    org_id: null,
    parent_id: null,
    logoUrl: '',
    compliance: { selected: ['nist'], states: [] }
  };
};

const OrgCreateEdit = ({
  selectedRootOrg,
  open,
  handleClose,
  saveOrg,
  org,
  orgList = [],
  hideParent = false
}) => {
  const [newOrg, setNewOrg] = useState({
    ...defaultOrg(),
    org_id: selectedRootOrg,
    parent_id: selectedRootOrg
  });
  const [prevOrgId, setPrevOrgId] = useState(null);

  if (org?._id !== prevOrgId) {
    setPrevOrgId(org?._id);

    const tempOrg = {
      ...defaultOrg(),
      org_id: selectedRootOrg,
      parent_id: selectedRootOrg
    };
    setNewOrg(org ? { ...tempOrg, ...org } : tempOrg);
  }

  if (!newOrg.compliance?.selected || !newOrg.compliance?.states) {
    const compliance = defaultOrg().compliance;
    setNewOrg({
      ...newOrg,
      compliance
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOrg((prevOrg) => ({
      ...prevOrg,
      [name]: value
    }));
  };

  const handleSelectChange = (newVal) => {
    setNewOrg((prevOrg) => ({
      ...prevOrg,
      parent_id: newVal ?? null
    }));
  };

  const handleSubmit = () => {
    const compliance = cloneDeep(newOrg.compliance);
    if (compliance.selected.indexOf('nist') < 0) {
      compliance.selected.push('nist');
      setNewOrg((prevOrg) => ({
        ...prevOrg,
        compliance
      }));
    }

    saveOrg({ ...newOrg, org_id: selectedRootOrg });
    handleClose();
  };

  const onComplianceSelectedChange = (e) => {
    console.log('org-create-edit onComplianceSelectedChange', e?.target?.name, e?.target?.value);
    const selected = [...newOrg?.compliance?.selected];
    const index = selected.indexOf(e.target.name);

    if (index < 0) {
      selected.push(e.target.name);
    } else {
      selected.splice(index, 1);
    }

    console.log('new SELECTED!', selected);
    const updatedOrg = cloneDeep(newOrg);
    updatedOrg.compliance.selected = selected;
    setNewOrg(updatedOrg);
  };

  const onComplianceStatesChange = (val) => {
    console.log('org-create-edit onComplianceStatesChange', val);
    const updatedOrg = cloneDeep(newOrg);
    updatedOrg.compliance.states = val;
    setNewOrg(updatedOrg);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{org?._id ? 'Edit Organization' : 'Add New Organization'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={newOrg.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="logoUrl"
          label="Logo URL"
          type="text"
          fullWidth
          variant="standard"
          value={newOrg.logoUrl || ''}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        {!hideParent && (
          <OrgSelectParent
            selectedId={newOrg.parent_id || ''}
            currOrg={newOrg}
            orgList={orgList}
            disabled={newOrg && newOrg._id === newOrg.org_id}
            onSelectedChange={handleSelectChange}
          ></OrgSelectParent>
        )}
        <OrgComplianceForm
          selected={newOrg?.compliance?.selected ?? []}
          states={newOrg?.compliance?.states ?? []}
          onSelectedChange={onComplianceSelectedChange}
          onStatesChange={onComplianceStatesChange}
        ></OrgComplianceForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgCreateEdit;
