import { httpService } from './http.service.js';

export const rmfActionService = {
  fetchAll,
  fetchByType,
  fetchBySource,
  getSuggested,
  save,
  getById
};

const ENDPOINT = 'rmf-actions';

async function fetchAll() {
  return httpService.get(`${ENDPOINT}/all`);
}

async function fetchByType(actionType) {
  return httpService.get(`${ENDPOINT}/type/${actionType}`);
}

async function fetchBySource(source) {
  return httpService.get(`${ENDPOINT}/source/${source}`);
}

async function getById(actionId) {
  return httpService.get(`${ENDPOINT}/${actionId}`);
}

async function getSuggested(count) {
  return httpService.get(`${ENDPOINT}/suggested/${count}`);
}
async function save(action) {
  let savedAction;
  if (action._id) {
    console.log('update ai product');
    savedAction = await httpService.put(`${ENDPOINT}/${action._id}`, action);
  } else {
    console.log('create ai product');
    savedAction = await httpService.post(`${ENDPOINT}`, action);
  }
  return savedAction;
}
