const { createContext, useEffect, useReducer, useContext } = require('react');
const { useParams } = require('react-router-dom');
const { orgService } = require('services/org.service');
const cloneDeep = require('lodash/cloneDeep');
const uniq = require('lodash/uniq');

const OrgDataContext = createContext();

const initialState = {
  orgList: [],
  isLoadingOrgs: false,
  rootOrg: null,
  rootOrgId: null,
  selectedOrg: null,
  selectedOrgId: null,
  selectedOrgList: [],
  selectedCompliance: []
};

/* Creates a list of selected org and all nested children, replaces orgListArray */
const getSelectedOrgList = (selectedOrg) => {
  if (!selectedOrg) return [];
  const result = [selectedOrg];
  let currNodes = selectedOrg.children ?? [];
  while (currNodes.length > 0) {
    let newNodes = [];
    for (const node of currNodes) {
      result.push(node);
      if (Array.isArray(node.children)) {
        newNodes.concat(node.children);
      }
    }
    currNodes = newNodes;
  }
  return result;
};

/* create a list of inhertied org.compliance.selected from root org -> seleceted org, no duplicates */
const getSelectedCompliance = (selectedOrg, orgList = []) => {
  let currNode = selectedOrg;
  let resultArr = selectedOrg?.compliance?.selected ?? [];

  const findParentOrg = (parent_id, orgList) => {
    return orgList?.find((org) => org._id === parent_id);
  };

  while (currNode?.parent_id || (currNode && currNode.org_id !== currNode._id)) {
    currNode = findParentOrg(currNode?.parent_id, orgList);
    const currCompliance = currNode?.compliance?.selected ?? [];
    resultArr = resultArr.concat(currCompliance);
  }
  return uniq(resultArr);
};

/* Reducer function to manage org data state */
const reducer = (state, action) => {
  switch (action.type) {
    // e.g: dispatch({type: 'SET_ORGS', orgList: [...], rootOrg: {...}, selectedOrg: {...}, selectedOrgList: [...], , selectedCompliance: [...]})
    case 'SET_ORGS':
      return {
        ...state,
        orgList: action.orgList,
        rootOrg: action.rootOrg ?? null,
        rootOrgId: action.rootOrg?._id ?? null,
        selectedOrg: action.selectedOrg ?? null,
        selectedOrgId: action.selectedOrg?._id ?? null,
        selectedOrgList: action.selectedOrgList ?? [],
        selectedCompliance: action.selectedCompliance ?? []
      };
    // e.g: dispatch({type: 'UPDATE_SELECTED', selectedOrg: {...}, selectedOrgList: [...], selectedCompliance: [...]})
    case 'UPDATE_SELECTED':
      return {
        ...state,
        selectedOrg: action.selectedOrg ?? null,
        selectedOrgId: action.selectedOrg?._id ?? null,
        selectedOrgList: action.selectedOrgList ?? [],
        selectedCompliance: action.selectedCompliance ?? []
      };
    // e.g: dispatch({type: 'UPDATE_ORG', index: 0, org: {...}})
    case 'UPDATE_ORG':
      const newOrgList = [...state.orgList];
      newOrgList[action.index] = action.org;
      return {
        ...state,
        orgList: newOrgList
      };
    // e.g: dispatch({type: 'REMOVE_ORG', id: '...'})
    case 'REMOVE_ORG':
      const newOrgList2 = [...state.orgList];
      const index = state.orgList.findIndex((org) => org._id === action.id);
      if (index > -1) {
        newOrgList2.splice(index, 1);
      }
      return {
        ...state,
        orgList: newOrgList2
      };
    // e.g: dispatch({type: 'SET_LOADING', isLoading: true})
    case 'SET_LOADING':
      return {
        ...state,
        isLoadingOrgs: Boolean(action.isLoading)
      };
    case 'RESET_STATE':
      return {
        ...state,
        ...cloneDeep(initialState)
      };
    default:
      throw new Error(`Unknown action type for org data reducer: ${action.type}`);
  }
};

/* fetches the entire org list, refreshes state **can be imported for use when modifying org data  */
const fetchOrgList = async (dispatch, selectedOrgId) => {
  dispatch({ type: 'SET_LOADING', isLoading: true });
  try {
    const orgList = await orgService.getOrgList(selectedOrgId);
    if (orgList?.length > 0) {
      const rootOrg = orgList.find((org) => org._id === org.org_id);
      const selectedOrg = orgList.find((org) => org._id === selectedOrgId);
      const selectedOrgList = getSelectedOrgList(selectedOrg);
      const selectedCompliance = getSelectedCompliance(selectedOrg, orgList);
      dispatch({
        type: 'SET_ORGS',
        orgList,
        rootOrg,
        selectedOrg,
        selectedOrgList,
        selectedCompliance
      });
    } else {
      dispatch({
        type: 'RESET_STATE'
      });
    }
  } catch (err) {
    console.error('Failed to fetch org list data', err);
  } finally {
    dispatch({ type: 'SET_LOADING', isLoading: false });
  }
};

/* Context provider */
function OrgDataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, cloneDeep(initialState));

  const { orgId } = useParams();

  useEffect(() => {
    const handleOrgIdChange = (orgId) => {
      if (!orgId || orgId === state.selectedOrgId) return;
      const newSelectedOrg = state.orgList.find((org) => org._id === orgId);
      if (state.orgList?.length < 1 || !newSelectedOrg) {
        // org not found, need new data
        fetchOrgList(dispatch, orgId);
      } else {
        // org found, update selected
        const selectedOrgList = getSelectedOrgList(newSelectedOrg);
        const selectedCompliance = getSelectedCompliance(newSelectedOrg, state.orgList);
        dispatch({
          type: 'UPDATE_SELECTED',
          selectedOrg: newSelectedOrg,
          selectedOrgList,
          selectedCompliance
        });
      }
    };

    handleOrgIdChange(orgId);
  }, [orgId, state.selectedOrgId, state.orgList]);

  const value = { state, dispatch };

  return <OrgDataContext.Provider value={value}>{children}</OrgDataContext.Provider>;
}

/* helper function for easier use */
function useOrgData() {
  const context = useContext(OrgDataContext);
  if (context === undefined) {
    throw new Error('useOrgData must be used within a OrgDataProvider');
  }
  return context;
}

export { OrgDataProvider, fetchOrgList, useOrgData };
