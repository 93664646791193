import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useParams
import { rmfActionService } from 'services/rmf-action.service';
import { rmfDocumentsService } from 'services/rmf-documents.service';
import { ai_productActorsService } from 'services/users.service';
import startCase from 'lodash/startCase';

const defaultPolicy = () => {
  return {
    type: 'Govern',
    title: '',
    ai_actors: [],
    source: 'custom'
  };
};

const CreateEditCustomPolicy = ({ policyType, policyId, selectedOrgId }) => {
  const [prevPolicyType, setPrevPolicyType] = useState(null);
  const [prevPolicyId, setPrevPolicyId] = useState(null);
  const [newPolicy, setNewPolicy] = useState({
    ...defaultPolicy()
  });
  const [aiActors, setAiActors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPolicyType, setSelectedPolicyType] = useState('action');
  const [policyInputVal, setPolicyInputVal] = useState('');

  const navigate = useNavigate();

  const fetchAiActors = async () => {
    try {
      const actors = await ai_productActorsService.getRoles();
      setAiActors(actors);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCustomPolicy = async (policyType, policyId) => {
    try {
      const policyData =
        policyType === 'action'
          ? await rmfActionService.getById(policyId)
          : policyType === 'document'
          ? await rmfDocumentsService.getById(policyId)
          : null;
      setNewPolicy(policyData);
      setPolicyInputVal(policyType === 'action' ? policyData.action : policyData.document);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAiActors();
  }, []);

  if (prevPolicyId !== policyId || prevPolicyType !== policyType) {
    setPrevPolicyId(policyId);
    setPrevPolicyType(policyType);

    if (policyType === 'new' && !policyId) {
      // new policy
      setNewPolicy(defaultPolicy());
      setPolicyInputVal('');
    } else if ((policyType === 'document' || policyType === 'action') && policyId) {
      // fetch existing
      fetchCustomPolicy(policyType, policyId);
    } else {
      console.warn(`Invalid custom policy ${policyType}, ${policyId}`);
      navigate('/not-found', {replace: true});
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPolicy((prevPolicy) => ({
      ...prevPolicy,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      newPolicy.org_id = selectedOrgId;
      if ((policyType === 'new' && selectedPolicyType === 'action') || policyType === 'action') {
        newPolicy.action = policyInputVal;
        await rmfActionService.save(newPolicy); // Update existing action
      } else if (
        (policyType === 'new' && selectedPolicyType === 'document') ||
        policyType === 'document'
      ) {
        newPolicy.document = policyInputVal;
        await rmfDocumentsService.save(newPolicy);
      }
      if (newPolicy._id) {
        return navigate(`../../../custom-policies`, { relative: 'path' });
      }
      return navigate(`../../custom-policies`, { relative: 'path' });
    } finally {
      setIsLoading(false);
    }
  };

  const policyInputLabel = useMemo(() => {
    if (policyType === 'new') {
      return startCase(selectedPolicyType);
    }
    return startCase(policyType);
  }, [policyType, selectedPolicyType]);

  return (
    <React.Fragment>
      <CardContent sx={{ padding: 0 }}>
        <Typography variant="subtitle2" component="div">
          {policyId ? `Edit Policy` : `New Policy`}
        </Typography>
        <Grid container spacing={2}>
          {policyType === 'new' && (
            <Grid item xs={6}>
              <FormControl fullWidth size="small" margin="dense" variant="outlined">
                <InputLabel>Type</InputLabel>
                <Select
                  id="selectedPolicyTypeId"
                  value={selectedPolicyType}
                  onChange={(e) => setSelectedPolicyType(e.target.value)}
                  label="Type"
                >
                  <MenuItem value="action">Action</MenuItem>
                  <MenuItem value="document">Document</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={6}>
            <FormControl fullWidth size="small" margin="dense" variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select name="type" value={newPolicy.type} onChange={handleChange} label="Category">
                <MenuItem value="Govern">Govern</MenuItem>
                <MenuItem value="Map">Map</MenuItem>
                <MenuItem value="Manage">Manage</MenuItem>
                <MenuItem value="Measure">Measure</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              margin="dense"
              name="action"
              label={policyInputLabel}
              type="text"
              fullWidth
              variant="outlined"
              value={policyInputVal}
              onChange={(e) => setPolicyInputVal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" margin="dense" variant="outlined">
              <InputLabel>AI Actors</InputLabel>
              <Select
                name="ai_actors"
                value={newPolicy.ai_actors}
                onChange={handleChange}
                label="AI Actors"
                multiple
              >
                {aiActors.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-around', width: '30rem', maxWidth: '100%' }}
        >
          <Button variant="contained" color="error" onClick={handleSubmit} disabled={isLoading}>
            Save Changes
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );
};

export default CreateEditCustomPolicy;
