import { Box, Tab, Tabs } from '@mui/material';
import { useOrgData } from 'contexts/org-data-context';
import { COMPLIANCE_DYNAMIC_TABS as DYNAMIC_TABS, COMPLIANCE_TABS as TABS } from 'js/constants';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ComplianceHeader from './compliance-header';

const ComplianceTabs = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(false);
  const {
    state: { selectedCompliance }
  } = useOrgData();
  const [computedTabs, setComputedTabs] = useState([]);

  useEffect(() => {
    const dyanmicArr = Array.isArray(selectedCompliance)
      ? DYNAMIC_TABS.filter((tab) => selectedCompliance.indexOf(tab.route) > -1)
      : [];
    const newTabs = TABS.concat(dyanmicArr);
    setComputedTabs(newTabs);

    setTabIndex(
      newTabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1) > -1
        ? newTabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1)
        : false
    );
  }, [selectedCompliance, location.pathname]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', mb: 1 }}>
        <ComplianceHeader />
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Compliance Tabs"
          sx={{ mb: 1 }}
        >
          {computedTabs.map((tab, index) => (
            <Tab key={tab.name} label={tab.name} component={Link} to={tab.route} />
          ))}
        </Tabs>
      </Box>
    </React.Fragment>
  );
};

export default ComplianceTabs;
