import { useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateEditCustomPolicy from './create-edit-custom-policy';

const ComplianceCustomPolicy = () => {
  const {
    state: { selectedOrgId }
  } = useOrgData();
  const [initialOrgId, setInitialOrgId] = useState(null);
  const { policyType, policyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrgId && !initialOrgId) {
      setInitialOrgId(selectedOrgId);
    } else if (initialOrgId && initialOrgId !== selectedOrgId) {
      if (policyId) {
        navigate('../../../custom-policies', { relative: 'path', replace: true });
      } else {
        navigate('../../custom-policies', { relative: 'path', replace: true });
      }
    }
  }, [selectedOrgId, initialOrgId, policyId, navigate]);

  return <React.Fragment>
    <CreateEditCustomPolicy policyType={policyType} policyId={policyId} selectedOrgId={selectedOrgId} />
  </React.Fragment>;
};

export default ComplianceCustomPolicy;
