import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Box,
  IconButton
} from '@mui/material';
import { ai_productActorsService } from 'services/users.service';
import CloseIcon from '@mui/icons-material/Close'; // Import the CloseIcon

const CreateEditAiProductActor = ({
  userToEdit,
  handleRolesChange,
  handleChange,
  handleSubmit
}) => {
  const [roles, setRoles] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false); // State to control Select's open state
  console.log('userToEdit', userToEdit);
  useEffect(() => {
    getRoles();
  }, []);
  const getRoles = async () => {
    try {
      const roles = await ai_productActorsService.getRoles();
      setRoles(roles);
      console.log('roles', roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  const handleCloseSelect = () => {
    setSelectOpen(false); // Manually close the Select dropdown
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Typography variant="subtitle2">
          {userToEdit._id ? 'Edit Product Actor' : 'New Product Actor'}
        </Typography>

        <TextField
          label="First Name"
          name="firstName"
          value={userToEdit.firstName}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          label="Last Name"
          name="lastName"
          value={userToEdit.lastName}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          label="Email"
          name="email"
          value={userToEdit.email}
          onChange={handleChange}
          fullWidth
        />

        <FormControl fullWidth>
          <InputLabel>AI Roles</InputLabel>
          <Select
            label="AI Roles"
            name="aiActors"
            multiple
            value={userToEdit?.aiActors || []}
            onChange={handleRolesChange}
            open={selectOpen} // Control the open state
            onOpen={() => setSelectOpen(true)} // Set open state when Select is opened
            onClose={handleCloseSelect} // Set close state when Select is closed
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 400, position: 'relative', maxWidth: 200 } // Control dropdown height and width
              }
            }}
          >
            {roles?.map((actor) => (
              <MenuItem key={actor} value={actor}>
                <Checkbox checked={userToEdit?.aiActors?.indexOf(actor) > -1} />
                <ListItemText primary={actor} />
              </MenuItem>
            ))}

            {/* Close Icon on the right */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <IconButton onClick={handleCloseSelect}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" color="primary">
          {userToEdit?._id ? 'Update User' : 'Create User'}
        </Button>
      </Stack>
    </form>
  );
};

export default CreateEditAiProductActor;
