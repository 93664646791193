import React from 'react';
import { Container } from '@mui/material';
import ComplianceVerticalTabs from '../compliance-vertical-tabs';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ display: 'flex', marginLeft: 0 }}>
        <ComplianceVerticalTabs />
        <Outlet />
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
