// AiProductList.jsx
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PaginationFormControl from 'components/pagination';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../icons';
import { aiProductService } from '../../services/ai-product.service';
import SearchInput from './ai_product_search';

const AiProductList = ({ selectedOrgList }) => {
  const [aiProducts, setAiProducts] = useState([]);
  const [aiProductsearchQuery, setAiProductSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const navigate = useNavigate();

  useEffect(() => {
    const loadAiProducts = async () => {
      const allAiProducts = await aiProductService.query();

      // Filter AI products to only include those whose org_id is in selectedOrgList
      const filteredAiProducts = allAiProducts.filter((product) =>
        selectedOrgList.some((org) => org._id === product.org_id)
      );

      setAiProducts(filteredAiProducts);
      console.log('Filtered AI Products:', filteredAiProducts);
    };

    loadAiProducts();
    setCurrentPage(1);
  }, [selectedOrgList]);

  const getOrgNameById = (orgId) => {
    const org = selectedOrgList.find((org) => org._id === orgId);
    return org ? org.name : 'Unknown';
  };

  const deleteProduct = async (productId) => {
    // TODO add confirmation before deleting
    // await aiProductService.remove(productId);
    // loadAiProducts();
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filteredProducts = aiProducts.filter((product) => {
    const orgName = getOrgNameById(product.org_id);
    return (
      product.product_name.toLowerCase().includes(aiProductsearchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(aiProductsearchQuery.toLowerCase()) ||
      orgName.toLowerCase().includes(aiProductsearchQuery.toLowerCase())
    );
  });

  // Calculate pagination
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  const navigateToProduct = (productId) => {
    if (productId) {
      return navigate(`../product/${productId}`, { relative: 'path' });
    } else {
      return navigate(`../product`, { relative: 'path' });
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ gap: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigateToProduct()}
          sx={{ width: '12rem' }}
        >
          Add Ai Product
        </Button>
        <SearchInput
          searchQuery={aiProductsearchQuery}
          setSearchQuery={setAiProductSearchQuery}
          label="Search AI Products" // Pass a custom label
        />

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Description</TableCell>
              {/* <TableCell>Organization ID</TableCell> */}
              <TableCell
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                Organization Name
              </TableCell>
              <TableCell>Type</TableCell>
              {/* <TableCell>Action Category</TableCell> */}
              <TableCell>Actions </TableCell>
              <TableCell>Documents </TableCell>
              <TableCell>Users</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProducts?.length < 1 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No products found
                </TableCell>
              </TableRow>
            ) : (
              currentProducts?.map((aiProduct) => (
                <TableRow key={aiProduct._id}>
                  <TableCell>{aiProduct.product_name}</TableCell>
                  <TableCell>{aiProduct.description}</TableCell>
                  {/* <TableCell>{aiProduct.org_id}</TableCell> */}
                  <TableCell>{getOrgNameById(aiProduct.org_id)}</TableCell>
                  <TableCell>{aiProduct.type}</TableCell>
                  <TableCell>{aiProduct.rmfActions?.length ?? 0}</TableCell>
                  <TableCell>{aiProduct.selectedDocuments?.length ?? 0}</TableCell>
                  <TableCell>
                    {(() => {
                      const displayedUserIds = new Set(); // Set to track unique user IDs

                      return aiProduct.rmfActions?.map((product) =>
                        product.users?.map((user) => {
                          if (!displayedUserIds.has(user._id)) {
                            displayedUserIds.add(user._id); // Add user ID to the set
                            return <div key={user._id}>{user.firstName + ' ' + user.lastName}</div>;
                          }
                          return null; // Skip rendering if the user has already been displayed
                        })
                      );
                    })()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => navigateToProduct(aiProduct._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteProduct(aiProduct._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AiProductList;
