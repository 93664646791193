import { useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useState } from 'react';
import { aiProductService } from 'services/ai-product.service';
import RmfMap from './rmf-map';

const ComplianceRMF = () => {
  const [aiProducts, setAiProducts] = useState([]);

  const {
    state: { selectedOrgList }
  } = useOrgData();

  useEffect(() => {
    const loadAiProducts = async () => {
      const allAiProducts = await aiProductService.query();

      // Filter AI products to only include those whose org_id is in selectedOrgList
      const filteredAiProducts = allAiProducts.filter((product) =>
        selectedOrgList.some((org) => org._id === product.org_id)
      );

      setAiProducts(filteredAiProducts);
      console.log('Filtered AI Products:', filteredAiProducts);
    };

    loadAiProducts();
  }, [selectedOrgList]);

  return (
    <React.Fragment>
      {/* <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        NIST RMF
      </Typography> */}

      <RmfMap aiProducts={aiProducts} />
      {/* <Departments onDepartmentSelect={handleDepartmentSelect} /> */}
    </React.Fragment>
  );
};

export default ComplianceRMF;
