import { GoogleLogin } from '@react-oauth/google';
import scrybLogo from 'assets/img/scryb_logo_lg_blue.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { httpService } from 'services/http.service';
import Cookies from 'universal-cookie';

const SignIn = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const onSuccess = async (credentialResponse) => {
    try {
      console.log(credentialResponse);
      const user = await httpService.post('auth/signin', {
        credential: credentialResponse.credential
      });

      if (user) {
        window.sessionStorage.setItem('user', JSON.stringify(user));

        const cookies = new Cookies();
        // TODO figure out why HTTPONLY doesnt work
        cookies.set('credential', credentialResponse.credential, {
          httpOnly: false,
          secure: process.env.NODE_ENV === 'production'
        });

        // TODO add 404 page
        let redirect = searchParams.get('r');
        if (redirect) {
          console.log(`redirecting to [${redirect}]`);
          try {
            navigate(redirect.startsWith('/') ? redirect : '/' + redirect);
          } catch (err) {
            console.error(err);
            navigate('/');
          }
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      console.error('sign-in onSuccess Error', err);
    }
  };

  const onError = (errorResponse) => console.log('SignIn err', errorResponse);

  return (
    <div className="login-container">
      <img
        src={scrybLogo}
        alt="scryb logo"
        style={{ width: '30rem', margin: '1rem', marginBottom: '3rem' }}
      />
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={(errorResponse) => {
          onError(errorResponse);
        }}
        size={'large'}
        shape={'rectangular'}
      />
    </div>
  );
};

export default SignIn;
