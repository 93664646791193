import { ButtonGroup, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ConfirmationDialog from 'components/dialog/confirmation-dialog';
import OrgCreateEdit from 'components/org/org-create-edit';
import { fetchOrgList, useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, PlusIcon } from '../../icons';
import { orgService } from '../../services/org.service';

const TreeHierarchicalList = () => {
  const [defaultExpanded, setDefaultExpanded] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNode, setselectedNode] = useState(null);

  // confirm delete dialog state
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] = useState({
    body: '',
    onConfirm: () => null
  });

  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { rootOrg, rootOrgId, selectedOrgId },
    dispatch
  } = useOrgData();

  useEffect(() => {
    const getAllNodeIds = (nodes) => {
      if (!nodes) return [];
      let nodeIds = [nodes._id];
      if (nodes.children) {
        Object.values(nodes.children).forEach((child) => {
          nodeIds = nodeIds.concat(getAllNodeIds(child));
        });
      }
      return nodeIds;
    };

    if (rootOrg && !defaultExpanded.length) {
      setDefaultExpanded(getAllNodeIds(rootOrg));
    }
  }, [rootOrg, defaultExpanded.length]);

  const treeData = useMemo(() => {
    if (!rootOrg) return [];
    return [rootOrg];
  }, [rootOrg]);

  const handleOpen = (org = null, isNew = false) => {
    console.log('Opening dialog:', { org, isNew });

    if (isNew) {
      setselectedNode({
        name: '',
        org_id: rootOrgId,
        parent_id: selectedOrgId
      });
    } else {
      setselectedNode(org);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setselectedNode(null);
  };

  const saveOrg = async (org) => {
    console.log('saveOrg selectedNode*****', selectedNode);
    if (selectedNode) {
      org.org_id = selectedNode.org_id;
      org.parent_id = selectedNode.parent_id;
    }
    console.log('saveOrg org', org);
    await orgService.save(org);

    fetchOrgList(dispatch, selectedOrgId);
    handleClose();
  };

  const openDeleteDialog = (org) => {
    console.log('showDeleteDialog!!', org);
    setDeleteDialogProps({
      body: `Delete organization '${org.name}' with id '${org._id}'? This action is permanent.'`,
      onConfirm: async () => {
        console.log('deleteOrg!', org._id);
        setShowDeleteDialog(false);
        await orgService.remove(org._id);
        navigateToId(org.parent_id);
        fetchOrgList(dispatch, org.parent_id);
      }
    });

    setShowDeleteDialog(true);
  };

  const handleNodeClick = (event, node) => {
    event.stopPropagation();
    navigateToId(node._id);
  };

  const navigateToId = (orgId) => {
    console.log('navigateToId', orgId);
    if (!orgId) return;
    const locationParts = location.pathname.split('/');
    locationParts[2] = orgId;

    navigate(locationParts.join('/'));
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes._id}
      itemId={nodes._id}
      label={
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <span style={{ marginRight: '10px' }}>{nodes.name}</span>
          {selectedOrgId === nodes._id && (
            <ButtonGroup variant="text" aria-label="Controls button group">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(nodes);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openDeleteDialog(nodes);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(nodes, true);
                }}
              >
                <PlusIcon />
              </IconButton>
            </ButtonGroup>
          )}
        </div>
      }
      onClick={(event) => handleNodeClick(event, nodes)}
    >
      {nodes.children && typeof nodes.children === 'object'
        ? Object.values(nodes.children).map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Box sx={{ minHeight: 352, maxWidth: 400 }}>
          {defaultExpanded?.length > 0 && (
            <SimpleTreeView
              defaultExpandedItems={defaultExpanded}
              selectedItems={selectedOrgId ?? null}
              expansionTrigger="iconContainer"
            >
              {treeData.map((data) => renderTree(data))}
            </SimpleTreeView>
          )}
        </Box>
      </Stack>
      {open && (
        <OrgCreateEdit
          open={open}
          handleClose={handleClose}
          saveOrg={saveOrg}
          org={selectedNode}
          hideParent={true}
        />
      )}
      {
        <ConfirmationDialog
          open={showDeleteDialog}
          title={'Confirm Delete'}
          onCancel={() => setShowDeleteDialog(false)}
          {...deleteDialogProps}
        />
      }
    </React.Fragment>
  );
};

export default TreeHierarchicalList;
