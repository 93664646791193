import { Box } from '@mui/material';
import { useOrgData } from 'contexts/org-data-context';
import React from 'react';

const ComplianceHeader = () => {
  const {
    state: { selectedOrg }
  } = useOrgData();

  return (
    <React.Fragment>
      {selectedOrg && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selectedOrg.logoUrl && (
            <img
              src={selectedOrg.logoUrl}
              alt={`${selectedOrg.name} logo`}
              style={{
                width: '100px',
                height: '50px',
                objectFit: 'contain',
                marginRight: '12px',
                borderRadius: '8px',
                border: '1px solid #ccc',
                padding: '5px'
              }}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
};

export default ComplianceHeader;
