import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useUserContext } from 'App';
import scrybLogo from 'assets/img/scryb_logo_sm_blue.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SignOutContainer from './sign-out-container';

// 66a3e92218ec387a5b73a56f
const Header = ({ isSignedIn, onSignOut }) => {
  const location = useLocation(); // Get the current location

  const { user } = useUserContext();
  const navigate = useNavigate();
  const shouldShowNavLinks = location.pathname !== '/';

  const activeStyle = {
    fontWeight: 'bold',
    color: '#53b4ef',
    borderBottom: '2px solid #53b4ef',
    paddingBottom: '4px'
  };

  const handleNav = (e) => {
    e.preventDefault();
    navigate('/compliance/66a3e92218ec387a5b73a56f');
  };

  // nav links in navbar
  const navLinks = isSignedIn && shouldShowNavLinks && (
    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'right', marginRight: '1rem' }}>
      {/* <NavLink
        to="/"
        // exact
        style={({ isActive }) => ({
          textDecoration: 'none',
          color: isActive ? activeStyle.color : 'inherit',
          fontWeight: isActive ? activeStyle.fontWeight : 'normal',
          borderBottom: isActive ? activeStyle.borderBottom : 'none',
          paddingBottom: isActive ? activeStyle.paddingBottom : 'none',
          marginRight: '16px'
        })}
      >
        <Typography variant="button" style={{ display: 'inline-block' }}>
          Home
        </Typography>
      </NavLink>
      <NavLink
        to="/compliance"
        onClick={handleNav}
        style={({ isActive }) => ({
          textDecoration: 'none',
          color: isActive ? activeStyle.color : 'inherit',
          fontWeight: isActive ? activeStyle.fontWeight : 'normal',
          borderBottom: isActive ? activeStyle.borderBottom : 'none',
          paddingBottom: isActive ? activeStyle.paddingBottom : 'none',
          marginRight: '16px'
        })}
      >
        <Typography variant="button" style={{ display: 'inline-block' }}>
          COMPLIANCE
        </Typography>
      </NavLink>
      <NavLink
        to="/org"
        style={({ isActive }) => ({
          textDecoration: 'none',
          color: isActive ? activeStyle.color : 'inherit',
          fontWeight: isActive ? activeStyle.fontWeight : 'normal',
          borderBottom: isActive ? activeStyle.borderBottom : 'none',
          paddingBottom: isActive ? activeStyle.paddingBottom : 'none'
        })}
      >
        <Typography variant="button" style={{ display: 'inline-block' }}>
          Organizations
        </Typography>
      </NavLink> */}
    </div>
  );

  // signout btn and avatar in navbar
  const signOutCntainer = isSignedIn && (
    <SignOutContainer srcImg={user?.picture} onClick={onSignOut}></SignOutContainer>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            width: '100%',
            padding: '0.35rem'
          }}
        >
          <NavLink to="/" style={{ textDecoration: 'none' }}>
            <img
              src={scrybLogo}
              alt="scryb logo"
              style={{ width: '8rem', marginRight: '0.5rem' }}
            />
          </NavLink>
          {navLinks}
        </div>
        {signOutCntainer}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
