import AssignmentIcon from '@mui/icons-material/Assignment';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { VERTICAL_TABS } from 'js/constants'; // Import only the static tabs
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ComplianceVerticalTabs = () => {
  const [verticalTabIndex, setVerticalTabIndex] = useState(0);
  const location = useLocation(); // Get the current location

  const iconMap = {
    dashboard: <DashboardIcon />,
    users: <PeopleIcon />,
    products: <CategoryIcon />,
    actions: <AssignmentIcon />
  };

  useEffect(() => {
    // Set active tab index based on current URL
    const currentIndex = VERTICAL_TABS.findIndex((tab) => location.pathname.includes(tab.route));
    setVerticalTabIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [location.pathname]);

  const handleVerticalTabChange = (event, newValue) => {
    setVerticalTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '60px' }}>
      <Tabs
        orientation="vertical"
        value={verticalTabIndex}
        onChange={handleVerticalTabChange}
        aria-label="Vertical tabs"
        sx={{
          '.MuiTabs-indicator': { display: 'none' },
          '.Mui-selected': { color: '#1976d2' } // This will affect the text and icon when selected
        }}
      >
        {VERTICAL_TABS.map((tab, index) => (
          <Tooltip
            title={tab.name}
            key={tab.name}
            placement="left"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      /* TODO remove this if button sizing / overflow is fixed */
                      offset: [0, -20]
                    }
                  }
                ]
              }
            }}
          >
            <Tab
              icon={React.cloneElement(iconMap[tab.icon], {
                sx: { color: verticalTabIndex === index ? '#1976d2' : 'inherit' } // Apply blue to the selected icon
              })}
              component={Link}
              to={tab.route}
              sx={{ justifyContent: 'flex-start' }}
            />
          </Tooltip>
        ))}
      </Tabs>
    </Box>
  );
};

export default ComplianceVerticalTabs;
