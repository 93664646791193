import { useOrgData } from 'contexts/org-data-context';
import React from 'react';
import CustomPoliciesList from './custom-policies-list';

const ComplianceCustomPolicies = () => {
  const {
    state: { selectedOrgId, selectedOrgList }
  } = useOrgData();
  return (
    <React.Fragment>
      <CustomPoliciesList
        selectedOrgId={selectedOrgId}
        selectedOrgList={selectedOrgList}
        aiProductOrgId={selectedOrgId}
      />
    </React.Fragment>
  );
};

export default ComplianceCustomPolicies;
