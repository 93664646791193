import { useOrgData } from 'contexts/org-data-context';
import React from 'react';
import AiProductList from './ai-product-list';

const ComplianceAIProducts = () => {
  const {
    state: { selectedOrgId, selectedOrgList }
  } = useOrgData();

  return (
    <React.Fragment>
      {/* <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        AI Product List
      </Typography> */}
      <AiProductList selectedOrgList={selectedOrgList} aiProductOrgId={selectedOrgId} />
    </React.Fragment>
  );
};

export default ComplianceAIProducts;
