import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PendingIcon from '@mui/icons-material/Pending';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import sum from 'lodash/sum';
import { NIST_ACTION_STATUSES as ACTION_STATUSES } from 'js/constants';

const ComplianceStatusTable = ({ label = 'Status', data }) => {
  return (
    <React.Fragment>
      <Table sx={{ minWidth: '25rem', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingY: '0.75rem' }}>{label}</TableCell>
            <TableCell sx={{ paddingY: '0.75rem' }}>Actions</TableCell>
            <TableCell sx={{ paddingY: '0.75rem' }}>Documents</TableCell>
            <TableCell sx={{ paddingY: '0.75rem' }}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ACTION_STATUSES.map((status) => (
            <TableRow key={status}>
              <TableCell
                sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
              >
                {(() => {
                  switch (status) {
                    case 'To Do':
                      return <NotStartedIcon color="secondary" />;
                    case 'In Progress':
                      return <PendingIcon color="primary" />;
                    case 'Completed':
                      return <CheckCircleIcon color="success" />;
                    default:
                      return null;
                  }
                })()}
                <Typography variant="button" sx={{ color: 'grey.600', paddingLeft: '0.5rem' }}>
                  {status}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="button" sx={{ color: 'secondary.main' }}>
                  {data?.[status]?.[0] ?? 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="button" sx={{ color: 'secondary.main' }}>
                  {data?.[status]?.[1] ?? 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="button" sx={{ color: 'secondary.main' }}>
                  {sum(data?.[status]) ?? 0}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default ComplianceStatusTable;
