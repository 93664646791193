import {
  Alert,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useOrgData } from 'contexts/org-data-context';
import React, { useEffect, useState } from 'react';
import { aiProductService } from 'services/ai-product.service';

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, YAxis } from 'recharts';

import { NIST_COLOR_MAP } from 'js/constants';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ width: '20rem' }}>
        <CardContent>
          <Typography
            variant="subtitle2"
            color={NIST_COLOR_MAP[payload[0].payload?.type] ?? 'text.secondary'}
            gutterBottom
          >
            {payload[0].payload?.title}
          </Typography>
          <Typography variant="body2">{payload[0].payload?.action}</Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};

const DashboardActionTotal = () => {
  const [topActions, setTopActions] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedRow, setSelectedRow] = useState(false);

  const {
    state: { selectedOrgList }
  } = useOrgData();

  useEffect(() => {
    const loadAiProducts = async () => {
      try {
        setSelectedRow(false);

        const allAiProducts = await aiProductService.query();

        // Filter AI products to only include those whose org_id is in selectedOrgList
        const filteredAiProducts = allAiProducts.filter((product) =>
          selectedOrgList.some((org) => org._id === product.org_id)
        );

        // Create a map to count action_ids
        const actionCountMap = {};

        filteredAiProducts.forEach((product) => {
          if (Array.isArray(product.rmfActions)) {
            product.rmfActions.forEach((action) => {
              const actionId = action.action_id;
              if (actionId) {
                actionCountMap[actionId] = (actionCountMap[actionId] || 0) + 1;
              }
            });
          }
        });

        // Convert map to array and sort by frequency
        const sortedActions = Object.entries(actionCountMap)
          .map(([actionId, count]) => {
            // Find corresponding action
            let actionDetails = {};
            filteredAiProducts.forEach((product) => {
              if (Array.isArray(product.rmfActions)) {
                product.rmfActions.forEach((action) => {
                  if (action.action_id === actionId) {
                    actionDetails = action;
                  }
                });
              }
            });
            return {
              actionId,
              action: actionDetails.action,
              title: actionDetails.title,
              type: actionDetails.title?.split(' ')[0].toLowerCase(),
              count
            };
          })
          .sort((a, b) => b.count - a.count)
          .slice(0, 10); // Take top 10 actions
        setTopActions(sortedActions);
      } finally {
        setIsInitialLoad(false);
      }
    };

    if (selectedOrgList && selectedOrgList.length > 0) {
      loadAiProducts();
    }
  }, [selectedOrgList]);

  // const handleBarClick = ({ activeLabel, activePayload }, event) => {
  const handleBarClick = ({ actionId }, index) => {
    console.log('handleBarClick', actionId, index);
    setSelectedRow(index);
    if (actionId) {
      const tableRow = document.getElementById(`tableRow-${actionId}`);
      tableRow?.scrollIntoView();
    }
  };

  const handleBarMouseEnter = (data, index) => {
    setSelectedRow(index);
  };

  return (
    <React.Fragment>
      <Paper elevation={0} square={false} style={{ width: '100%' }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ padding: 3 }}>
          Top 10 Frequent Actions
        </Typography>
        <Paper elevation={2} square={false} sx={{ padding: 2 }}>
          {isInitialLoad ? (
            <Box sx={{ width: '100%', minHeight: '400px', display: 'flex', alignItems: 'center' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              {topActions.length < 1 ? (
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '350px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Alert severity="warning">No Chart Data</Alert>
                </Box>
              ) : (
                <BarChart width="100%" height={350} maxBarSize={35} data={topActions}>
                  <YAxis dataKey="count" orientation="right" allowDecimals={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey="count"
                    background={{ fill: '#eee' }}
                    onClick={handleBarClick}
                    onMouseEnter={handleBarMouseEnter}
                  >
                    {topActions.map((row, index) => (
                      <Cell
                        key={`cell-${row.actionId}`}
                        cursor="pointer"
                        fill={NIST_COLOR_MAP[row.type]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              )}
            </ResponsiveContainer>
          )}
        </Paper>

        <Paper elevation={2} square={false} sx={{ marginY: 2 }}>
          {isInitialLoad ? (
            <Box sx={{ width: '100%', minHeight: '150px', display: 'flex', alignItems: 'center' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <TableContainer component={Paper} elevation={0} square={false}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>#</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Action</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Title</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Count</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topActions.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="subtitle2" sx={{ textAlign: 'center', width: '100%' }}>
                          No Frequent Actions
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {topActions.map((action, index) => (
                    <TableRow key={action.actionId} selected={selectedRow === index}>
                      <TableCell>
                        <span id={'tableRow-' + action.actionId}>{index + 1}</span>
                      </TableCell>
                      <TableCell>{action.action}</TableCell>
                      <TableCell>{action.title}</TableCell>
                      <TableCell align="right">{action.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default DashboardActionTotal;
