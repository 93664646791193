import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import OrgCreateEdit from 'components/org/org-create-edit';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { rootOrgService } from 'services/root-org.service';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Home = () => {
  const [orgs, setOrgs] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadOrgs();
  }, []);

  const loadOrgs = async () => {
    try {
      const orgs = await rootOrgService.query();
      const fileteredOrgs = orgs.filter((org) => org.parent_id === null);
      console.log('org home', fileteredOrgs);

      setOrgs(fileteredOrgs);
      console.log('orgs', orgs);
    } catch (err) {
      console.error('Error loading orgs', err);
    } finally {
      setIsLoaded(true);
    }
  };

  const handleOpen = (org = null) => {
    setSelectedOrg(org);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrg(null);
  };

  const saveOrg = async (org) => {
    if (selectedOrg) {
      org._id = selectedOrg._id;
    }
    await rootOrgService.save(org);
    loadOrgs();
    handleClose();
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className={className}
        style={{ ...style, display: 'block', color: 'black', right: '-1.5rem' }}
        onClick={onClick}
      />
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className={className}
        style={{ ...style, display: 'block', color: 'black', left: '-1.5rem' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true, // Ensure arrows are enabled
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <>
      <div style={{ maxWidth: '85vw', margin: '6.25rem auto', minHeight: '12.5rem' }}>
        {isLoaded &&
          (orgs.length > 0 ? (
            <Slider {...settings}>
              {orgs.map((item) => (
                <div key={item._id}>
                  <Item item={item} />
                </div>
              ))}
            </Slider>
          ) : (
            <div>No organizations available.</div>
          ))}
      </div>
      <div className="home-btn-container">
        <Button
          sx={{ width: '12rem', maxWidth: '90vw' }}
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Organization
        </Button>
      </div>

      <OrgCreateEdit
        open={open}
        handleClose={handleClose}
        saveOrg={saveOrg}
        org={selectedOrg}
        hideParent={true}
      />
    </>
  );
};

const Item = ({ item }) => {
  return (
    <Paper
      elevation={0}
      style={{
        padding: '0.75rem',
        textAlign: 'center',
        width: '6.25rem',
        height: '10rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.75rem'
      }}
    >
      <Link to={`/compliance/${item._id}/dashboard`}>
        <img
          src={item.logoUrl}
          alt={item.name}
          style={{ maxWidth: '80%', maxHeight: '8rem', minHeight: '4rem', objectFit: 'contain' }}
        />
      </Link>
    </Paper>
  );
};

export default Home;
