import React, { useCallback, useMemo } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import SearchInput from 'components/ai-product/ai_product_search';
import PaginationFormControl from 'components/pagination';
import { useOrgData } from 'contexts/org-data-context';
import { useEffect, useState } from 'react';
import { aiProductService } from 'services/ai-product.service';

const ComplianceActions = ({ compliance }) => {
  const {
    state: { selectedOrgList }
  } = useOrgData();

  const [aiProducts, setAiProducts] = useState([]);
  const [filteredProductsWithActions, setFilteredProductsWithActions] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('all');
  const [selectedUser, setSelectedUser] = useState('all');

  // const indexOfLastProduct = currentPage * itemsPerPage;
  // const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  // const currentProducts = filteredProductsWithActions.slice(
  //   indexOfFirstProduct,
  //   indexOfLastProduct
  // );
  const pageCount = Math.ceil(filteredProductsWithActions.length / itemsPerPage);

  const getOrgNameById = useCallback(
    (orgId) => {
      const org = selectedOrgList.find((org) => org._id === orgId);
      return org ? org.name : 'Unknown';
    },
    [selectedOrgList]
  );

  useEffect(() => {
    const loadAiProducts = async () => {
      const allAiProducts = await aiProductService.query();

      const filteredAiProducts = allAiProducts.filter((product) =>
        selectedOrgList.some((org) => org._id === product.org_id)
      );
      console.log('filteredAiProducts', filteredAiProducts);
      setAiProducts(filteredAiProducts);
    };

    loadAiProducts();
  }, [selectedOrgList]);

  useEffect(() => {
    if (aiProducts.length > 0) {
      const productsWithActions = aiProducts.filter(
        (product) => product.rmfActions && product.rmfActions.length > 0
      );

      setFilteredProductsWithActions(productsWithActions);
      setCurrentPage(1);
    }
  }, [aiProducts]);

  const filteredResults = useMemo(() => {
    return filteredProductsWithActions
      .filter((product) => selectedProduct === 'all' || product.product_name === selectedProduct)
      .map((product) => {
        const filteredActions = product.rmfActions.filter((action) => {
          const actionMatchesSearch = action.action
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const userMatchesFilter =
            selectedUser === 'all' ||
            (action.users && action.users.some((user) => user._id === selectedUser));
          return actionMatchesSearch && userMatchesFilter; // Both conditions need to be true
        });
        return {
          ...product,
          rmfActions: filteredActions
        };
      })
      .filter((product) => product.rmfActions.length > 0);
  }, [filteredProductsWithActions, selectedProduct, searchQuery, selectedUser]);

  const handleItemsPerPageChange = useCallback((event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    setCurrentPage(newPage);
  }, []);
  const renderedUsers = new Set();

  return (
    <React.Fragment>
      {/* <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        Action
      </Typography> */}
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ gap: 3 }}>
        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} label="Search" />
        <FormControl variant="outlined" sx={{ width: '10em' }}>
          <InputLabel id="product-select-label">Product</InputLabel>
          <Select
            labelId="product-select-label"
            sx={{ height: '2.4rem' }}
            name="product"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            label="Product"
          >
            <MenuItem value="all">All</MenuItem>
            {filteredProductsWithActions.map((product) => (
              <MenuItem key={product._id} value={product.product_name}>
                {product.product_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ width: '10em' }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            sx={{ height: '2.4rem' }}
            name="user"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="User"
          >
            <MenuItem value="all">All</MenuItem>
            {filteredProductsWithActions.flatMap(
              (product) =>
                product.rmfActions
                  ? product.rmfActions.flatMap(
                      (action) =>
                        action.users
                          ? action.users
                              .filter((user) => {
                                if (renderedUsers.has(user._id)) {
                                  return false; // If user already rendered, skip it
                                } else {
                                  renderedUsers.add(user._id); // Add user to the set
                                  return true; // If user not rendered, allow it
                                }
                              })
                              .map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {user.firstName + ' ' + user.lastName}
                                </MenuItem>
                              ))
                          : [] // If action.users is undefined, return an empty array
                    )
                  : [] // If product.rmfActions is undefined, return an empty array
            )}
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Org Name</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResults?.length < 1 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No actions found
                </TableCell>
              </TableRow>
            ) : (
              filteredResults.map((product) =>
                product.rmfActions.map((action, index) => (
                  <TableRow key={index + '-' + action.action_id}>
                    {/* Display the Org Name and Product Name in each row */}
                    <TableCell>{getOrgNameById(product.org_id)}</TableCell>
                    <TableCell>{product.product_name}</TableCell>
                    <TableCell>{action.action}</TableCell>
                    <TableCell>
                      {action.users?.map((user, index) => (
                        <div key={index}>{`${user.firstName} ${user.lastName}`}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ComplianceActions;
