import {
  Alert,
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useOrgData } from 'contexts/org-data-context';
import { PRODUCT_TYPES } from 'js/constants';
import sum from 'lodash/sum';
import React, { useEffect, useMemo, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { aiProductService } from 'services/ai-product.service';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DashboardProductsType = () => {
  const [typeCounts, setTypeCounts] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    state: { selectedOrgList }
  } = useOrgData();

  useEffect(() => {
    const loadAiProducts = async () => {
      try {
        const allAiProducts = await aiProductService.query();

        // Filter AI products to only include those whose org_id is in selectedOrgList
        const filteredAiProducts = allAiProducts.filter((product) =>
          selectedOrgList.some((org) => org._id === product.org_id)
        );

        // Calculate the number of products for each type
        const counts = filteredAiProducts.reduce((acc, product) => {
          acc[product.type] = (acc[product.type] || 0) + 1;
          return acc;
        }, {});

        // add missing keys / counts with 0
        for (const typeKey of PRODUCT_TYPES) {
          if (!(typeKey in counts)) {
            counts[typeKey] = 0;
          }
        }
        setTypeCounts(counts);
      } finally {
        setIsInitialLoad(false);
      }
    };

    if (selectedOrgList && selectedOrgList.length > 0) {
      loadAiProducts();
    }
  }, [selectedOrgList]);

  // Prepare the data for the PieChart
  const pieChartData = useMemo(() => {
    return Object.entries(typeCounts).map(([type, count]) => ({
      name: type,
      value: count
    }));
  }, [typeCounts]);

  const isEmptyData = useMemo(() => sum(Object.values(typeCounts)) < 1, [typeCounts]);

  return (
    <React.Fragment>
      <Paper elevation={0} square={false} style={{ width: '100%' }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ padding: 3 }}>
          AI Product Types
        </Typography>

        {/* Pie Chart */}
        <Paper elevation={2} square={false} sx={{ padding: 2 }}>
          {isInitialLoad ? (
            <Box sx={{ width: '100%', minHeight: '400px', display: 'flex', alignItems: 'center' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              {isEmptyData ? (
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '350px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Alert severity="warning">No Chart Data</Alert>
                </Box>
              ) : (
                <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={100}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, value }) => `${name}: ${value}`}
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend verticalAlign="bottom" />
                </PieChart>
              )}
            </ResponsiveContainer>
          )}
        </Paper>

        {/* Table Beneath the Chart */}
        <Paper
          elevation={2}
          square={false}
          sx={{ display: 'flex', justifyContent: 'center', marginY: 2 }}
        >
          {isInitialLoad ? (
            <Box
              sx={{
                width: '100%',
                minHeight: '150px',
                display: 'flex',
                alignItems: 'center',
                paddingX: 2
              }}
            >
              <LinearProgress sx={{ width: '100%' }} />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                padding: '0.75rem',
                width: '30rem',
                maxWidth: '100%'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Product Type</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Count</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(typeCounts).map(([type, count]) => (
                    <TableRow key={type}>
                      <TableCell>{type}</TableCell>
                      <TableCell align="right">{count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default DashboardProductsType;
